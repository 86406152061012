<script setup lang="ts">
import UiContent from '#core/components/text/UiContent.vue'

export interface Props {
  resource: Resource
  showFooter?: boolean
}

export interface Resource {
  link: string
  title: string
  type: string
}

export type Resources = Array<Resource>

const props = withDefaults(defineProps<Props>(), {
  showFooter: true,
})

const emit = defineEmits<{
  close: [_: void]
}>()
</script>

<template>
  <div
    data-ui="UiResourcesSlideover"
    class="flex-1 side-panel border-l border-forgd-bgd-600 bg-forgd-bgd-100 font-display overflow-y-scroll flex flex-col"
  >
    <div
      class="h-[70px] flex-shrink-0 px-5 flex items-center justify-between border-b border-forgd-bgd-600 sticky top-0 bg-forgd-bgd-100 z-50"
    >
      <div class="text-sm/[1.5] font-semibold">
        <slot name="heading">
          Readings & Resources
        </slot>
      </div>

      <UButton
        size="sm"
        color="white"
        variant="ghost"
        icon="i-heroicons-x-mark"
        @click="emit('close')"
      />
    </div>

    <div class="flex-grow p-6">
      <UiContent :path="props.resource.link" filter="/pathfinder/articles" />
    </div>

    <div
      v-if="props.showFooter"
      class="p-5 border-t border-forgd-bgd-600 bg-forgd-bgd-100 sticky bottom-0"
    >
      <UButton
        variant="solid"
        color="black"
        class="w-full items-center justify-center h-[50px]"
        icon="i-heroicons-arrow-top-right-on-square"
        trailing
        :ui="{
          font: 'font-display font-semibold',
        }"
        :to="resource.link"
      >
        Read the full article in Pathfinder
      </UButton>
    </div>
  </div>
</template>
